(function($) {

  $(document).ready( function($) {
    
    /**
     * Navs
     */
    $('[data-toggle="offcanvas"]').click(function () {
      $('.row-offcanvas').toggleClass('active');
    });

    var getUrlParameter = function getUrlParameter(sParam) {
      var sPageURL = decodeURIComponent(window.location.search.substring(1)),
          sURLVariables = sPageURL.split('&'),
          sParameterName,
          i;

      for (i = 0; i < sURLVariables.length; i++) {
          sParameterName = sURLVariables[i].split('=');

          if (sParameterName[0] === sParam) {
              return sParameterName[1] === undefined ? true : sParameterName[1];
          }
      }
    };

    // Offcanvas submenu
    $('.nav-offcanvas a').not(':only-child').click( function(event) {

      if(event.preventDefault) {
        event.preventDefault();
      }

      $(this).next('.sub-menu').slideToggle();
    });

    /**
     * Menu active
     */
    page = window.location.pathname;
    page = page.substr( page.lastIndexOf('/') );
    page = page.replace('/', '');
    
    if ( ! page ) {
      $('a[href="/"]').parent('li').addClass('active');
    }
    else {
      $('a[href="'+page+'"]').parent('li').addClass('active');
    }

    arr1 = ['good-credit.html', 'poor-credit.html', 'no-credit-history.html', 'just-like-cash.html', 'your-balance.html', 'rent-arrears-ccj.html', 'no-deposit.html', 'comprehensive-warranty.html' ];

    arr2 = ['press.html', 'media.html', 'technology.html', 'car-news.html', 'financial-news.html'];

    if( $.inArray(page, arr1 ) !== -1 ) {
      $('a[href="car-credit.html"]').parent('li').addClass('active');
    }
    else if( $.inArray(page, arr2 ) !== -1 ) {
      $('a[href="news.html"]').parent('li').addClass('active');
    }

    if( page !== 'car-credit.html' ) {
      $('.nav-subpages a[href="car-credit.html"]').parent('li').removeClass('active');
    }

    $('.quick-menu a').click(function(event) {
      if(event.preventDefault) {
        event.preventDefault();
      }
      $(this).toggleClass('active');
      $('.nav-subpages').toggleClass('active');
    });

    /**
     * Load iframe data from url
     */
    amount = window.location.search;

    if( page === 'apply-now.html' && amount.indexOf('amount') > 0 ) {
      
      $('#apply-now-app').attr('src', $('#apply-now-app').attr('src') + '&amount=' + getUrlParameter('amount').replace(/\D/g,'') + '&term=' + getUrlParameter('term').replace(/\D/g,'') );
    }


    $('.loan-apply').on( 'click', '.btn-loan-apply', function() {

      if(event.preventDefault) {
        event.preventDefault();
      }

      $btn = $('.btn-loan-apply');
      
      amount = $btn.data('amount');
      period = $btn.data('period');

      if( $.isNumeric( amount ) ) {
        amount = parseInt( amount );
      }
      else {
        amount = parseInt( amount.replace(/\D/g,'') );
      }

      new_url = $btn.attr('href') + '?amount=' + amount + '&term=' + period;

      window.location.href = new_url;
    });

    /**
     * Loan Calculator
     */
    if( $('.calculator select').length > 0 ) {
      calc_period   = [ 1, 3, 6, 12, 18, 24, 36 ];
      var selectize = $('.calculator select').selectize();
      form = selectize[0].selectize;

      $.each( calc_period, function(index, val) {
        
        postfix = ' month';

        if( val !== 1 ) {
          postfix = postfix + 's';
        }

        form.addOption({value: val, text: val + postfix });
      });

      form.refreshOptions(false);
      form.setValue(6);
    }

    /**
     * Instant Application
     */
    var slider_amount = document.getElementById('loan-amount');
    var slider_period = document.getElementById('loan-period');

    if( slider_amount ) {

      noUiSlider.create( slider_amount, {
        start: 500,
        step: 100,
        connect: 'lower',
        tooltips: true,
        format: wNumb({
            decimals: 0,
            thousand: '.',
            prefix: '£',
          }),
        direction: 'ltr',
        orientation: 'horizontal',
        range: {
          'min': [100],
          'max': [2500]
        },
      });

      val = slider_amount.noUiSlider.get().replace(/\D/g,'');

      $('span[data-amount]').html( slider_amount.noUiSlider.get() ).attr('data-amount', val );
      $('.btn-loan-apply').attr('data-amount', val );

      slider_amount.noUiSlider.on('slide', function( values ) {
        $('span[data-amount]').html( values ).attr('data-amount', values );
        $('.btn-loan-apply').attr('data-amount', values );
      });

      /**
       * Slide on click
       */
      $('.amount .slide').click( function(event) {
        if(event.preventDefault) {
          event.preventDefault();
        }

        if( $(this).data('action') === 'minus' ) {
          slideto = parseInt( slider_amount.noUiSlider.get().replace(/\D/g,'') ) - 50;
        }
        else {
          slideto = parseInt( slider_amount.noUiSlider.get().replace(/\D/g,'') ) + 50;
        }
        $('.btn-loan-apply').attr('data-amount', slideto );
        $('span[data-amount]').html( '£' + slideto ).attr('data-amount', slideto );

        slider_amount.noUiSlider.set( slideto );
      });
    }

    /**
     * Months selection
     */
    if( slider_period ) {

      noUiSlider.create( slider_period, {
        start: 6,
        connect: 'lower',
        step: 1,
        tooltips: true,
        format: wNumb({
          decimals: 0,
          thousand: '',
          postfix: ' <span>months</span>',
        }),
        direction: 'ltr',
        orientation: 'horizontal',
        range: {
          'min': 1,
          'max': 18
        },
      });

      postfix = ' month';

      if( parseInt( slider_period.noUiSlider.get() ) !== 1 ) {
        postfix = postfix + 's';
      }

      $('span[data-period]').html( parseInt( slider_period.noUiSlider.get() ) + postfix ).attr('data-period', parseInt( slider_period.noUiSlider.get() ) );
      $('.btn-loan-apply').attr('data-period', parseInt( slider_period.noUiSlider.get() ) );

      slider_period.noUiSlider.on('slide', function( values ) {

        postfix = ' month';

        if( parseInt(values) !== 1 ) {
          postfix = postfix + 's';
        }

        $('span[data-period]').html( parseInt(values) + postfix ).attr('data-period', parseInt(values) );
        $('.btn-loan-apply').attr('data-period', parseInt( values ) );
      });

      /**
       * Slide on click
       */
      $('.terms .slide').click( function(event) {
        if(event.preventDefault) {
          event.preventDefault();
        }

        arr  = [ 1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16 ];
        curr = $.inArray( parseInt( slider_period.noUiSlider.get().replace(/\D/g,'') ), arr );
        postfix = ' month';

        if( $(this).data('action') === 'minus' ) {
          slideto = curr - 1;
        }
        else {
          slideto = curr + 1;
        }

        if( parseInt(slideto) !== 1 ) {
          postfix = postfix + 's';
        }
        
        $('.btn-loan-apply').attr('data-period', slideto );
        $('span[data-period]').html( slideto + postfix ).attr('data-period', slideto );

        slider_period.noUiSlider.set( slideto );
      });
    }
  });
})(jQuery);