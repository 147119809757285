(function($) {
  $(document).ready( function($) {

    $('#btn-contact').click(function(event) {
      
      if(event.preventDefault) {
        event.preventDefault();
      }

      var name  = $('input[name=name]').val(),
          email = $('input[name=email]').val(),
          msg   = $('textarea[name=message]').val(),
          error = false;

      if( name === '' || email === '' || msg === '' ) {
        error = 'All fields are required';
        $('.response').text(error);
        return;
      }

      $.ajax({
        url: 'mailer.php',
        data: {
          name : name,
          email : email,
          msg : msg
        },
        type: 'post',
        success: function( data, textStatus, XMLHttpRequest ) {
            var message = 'Your message was sent and received.';
            $('.response').text(data);
            
            /*console.log( data );
            console.log( XMLHttpRequest );*/
            
        },
        error: function( MLHttpRequest, textStatus, errorThrown ) {
            var message = 'Your message could not be sent or received. Please try again later';

            $('.response').text(data);
/*
            console.log( MLHttpRequest );
            console.log( textStatus );
            console.log( errorThrown );*/
        }
      });


    });
    
  });
})(jQuery);